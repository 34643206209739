.routerErrorPage {
    &Container {
        position: fixed;
        left: 0;
        top: 0;
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        max-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &Icon {
        max-width: 200px;
        max-height: 200px;
        width: 100%;
        height: 100%;
        padding: 50px;

        border-radius: 100px;
        border: 7px solid rgba(108, 196, 208, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;

        & .MuiSvgIcon-root {
            width: 100px;
            height: 100px;
        }
    }
    &Content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 36px;
    }
    &Text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    &Title.MuiTypography-root {
		color: var(--global-text-primary-color);
		font-family: var(--global-font-family);
        text-align: center;
        font-size: 35px;
        font-style: normal;
        font-weight: 700;
        line-height: 133.4%;
    }
    &Description.MuiTypography-root {
        max-width: 686px;
		color: var(--global-text-primary-color);
		font-family: var(--global-font-family);
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 133.4%; 
    }
}