.planContentSeatsInput {
  display: flex;
  border-radius: 15px;
  border: 1px solid rgba(196, 196, 196, 1);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;

  & > div > div > .MuiInputBase-input {
	padding: 8px;
	color: rgba(196, 196, 196, 1);
  }

  & > div > div > .MuiOutlinedInput-notchedOutline {
	border: none;
  }

  .subtractButton {
	border-top: 0;
	border-bottom: 0;

	.subtractIcon {
	  color: rgba(196, 196, 196, 1);
	}
  }

  .addButton {
	border: 0;

	.addIcon {
	  color: rgba(196, 196, 196, 1);
	}
  }
}