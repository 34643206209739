.licenseSettings {
  padding: 15px;

  .licenseSubmenu.MuiTypography-root {
	color: rgba(24, 56, 78, 0.8);
	font-size: 16px;
	font-weight: 500;
	line-height: 15px;
	text-decoration: none;
	cursor: pointer;

	svg {
	  margin-right: 6px;
	}
  }

  .active.MuiTypography-root {
	color: var(--Primary-Main, #825ECA);
  }

  .submenuContent {
	padding: 0 15px;

	& .MuiTypography-root {
	  color: var(--Text-Primary, #09151D);
	}

	.licenseTitleContainer {
	  display: flex;
	  align-items: center;
	  justify-content: space-between;

	  .licenseTitleWrapper {
		display: flex;
	  }

	  .changePlanButton.MuiButtonBase-root {
		color: #825ECA;
		border: 1px solid rgba(130, 94, 202, 0.50);
		font-size: 13px;
		font-weight: 500;
		line-height: 22px;
		letter-spacing: 0.46px;
	  }

	  .licenseName {
		&::first-letter {
		  text-transform: capitalize;
		}

		color: #5B428D;
		font-size: 20px;
		font-weight: 700;
		margin-right: 12px;
	  }

	  .periodChip {
		& ::first-letter {
		  text-transform: capitalize;
		}

		border: 1px solid #006153;

		.MuiChip-label {
		  color: #006153;
		  font-size: 13px;
		  font-weight: 400;
		  line-height: 18px;
		  letter-spacing: 0.16px;
		}
	  }
	}

	.pricePerSeat, .seatsCount {
	  margin-top: 3px;
	  font-size: 16px;
	  font-weight: 600;
	}

	.benefitsAccordion {
	  margin-top: 15px;
	  border-radius: 12px;
	  border: 1px solid rgba(25, 59, 82, 0.15);
	  box-shadow: none;

	  &:before {
		height: 0;
	  }

	  .benefitsTitle {
		font-size: 16px;
		font-weight: 600;
	  }

	  .benefitContainer {
		justify-content: space-between;
		gap: 0 10px;
	  }

	  .benefitContainer, .benefitItem {
		display: flex;
		flex-wrap: wrap;

		.benefitItem .MuiTypography-root {
			color: #193B52;
			font-size: 14px;
			font-weight: 500;
		}
	  }
	}

	.subscriptionDetails {
	  margin-top: 19px;
	  font-size: 18px;
	  font-weight: 700;
	}

	.periodBilling {
	  margin-top: 28px;
	  font-size: 16px;
	  font-weight: 600;
	}

	.nextPeriod, .newSeats {
	  font-size: 14px;
	  font-weight: 400;
	  margin-bottom: 8px;
	}

	.newSeats {
	  margin-top: 12px;
	}

	.seatsContainer {
	  margin-top: 15px;
	  display: flex;
	  gap: 2em;
	  justify-content: space-between;

	  .title.MuiTypography-root {
		color: var(--Text-Primary, #09151D);
		font-size: 16px;
		font-weight: 500;
	  }

	  .seatsRow {
		display: flex;
		gap: 10px;

		.subtractButton {
		  border-radius: 13px;
		  background: #C4C3C5;
		  border: 0;

		  .subtractIcon {
			color: white;
		  }
		}

		.addButton {
		  border-radius: 13px;
		  background: #E7E7E7;
		  border: 0;
		}
	  }

	  .priceSeatsContainer {
		text-align: right;
	  }

	  .pricePerSeat {
		font-size: 16px;
		font-weight: 500;
	  }

	  .pricePerSeatExplanation {
		text-align: right;
		font-size: 14px;
		font-weight: 400;
	  }
	}

	.activeSeatsProgressBarText.MuiTypography-root {
		margin-top: 15px;
		color: var(--Text-Primary, #09151D);
		font-family: "Avenir Next";
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}

	.activeSeatsProgressBar {
		.bg-tremor-brand {
			background-color: var(--Primary-Main, #825ECA);
		}
	}
  }

  .infoIcon {
	margin-right: 10px;
  }

  .checkIcon {
	margin-right: 4px;
	width: 16px;
	color: green;
  }

  .actionsContainer {
	margin-top: 19px;
	float: right;
	.actionsWrapper {
	  display: flex;
	  gap: 10px;

	  .saveSubscriptionButton.MuiButtonBase-root:not(.Mui-disabled) {
		background-color: #825ECA;
		color: white;
	  }
	}


  }
}