body {
  margin: 0;
  font-family: var(--global-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FAFAF9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --global-primary-color: #015f6b;
  --global-default-color: #BBBBB2;
  --global-text-primary-color: #44546F;
  --global-text-on-primary-color: white;
  --global-outline-color: #E0E0E0;
  --global-purple-color: #825ECA;

  --global-font-family: 'Avenir Next', 'Open Sans', 'Segoe UI,', 'Roboto', sans-serif;

  --card-text-color: #151C33;
  --card-icon-color: #684BA2;
}
