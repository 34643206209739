.MuiTypography-root {
  font-family: var(--global-font-family);
}

.trialContainer {
  background: radial-gradient(89.52% 134.44% at 22.95% 0%, rgba(161, 120, 250, 0.04) 0%, rgba(163, 117, 252, 0.04) 17.71%, rgba(107, 197, 209, 0.06) 35.86%, rgba(0, 109, 122, 0.04) 57.9%, rgba(163, 117, 252, 0.03) 91.9%), #FFF;
  height: 100vh;
  padding: 38px 51px;

  .titleRow {
	display: flex;
	gap: 17px;
	align-items: center;

	& .MuiTypography-root {
	  color: #151C33;
	  font-size: 25px;
	  font-weight: 700;
	  font-family: var(--global-font-family);
	}
  }

  .contentColumn {
	margin-top: 75px;
	color: #44546F;

	.title.MuiTypography-root {
	  font-size: 50px;
	  font-weight: 600;
	}

	.subtitle {
	  margin: 38px 0 50px 0;
	}

	.explanation.MuiTypography-root {
	  font-size: 25px;
	  font-weight: 500;
	  line-height: 133.4%;
	}

	.benefit {
	  margin: 15px 23px;
	  display: flex;
	  align-items: center;
	  gap: 15px;

	  .icon {
		fill: url(#benefitGradient);
	  }
	}
  }
  .login {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	.termsSection {
		margin-top: 18px;

	  .termsLink {
		color: var(--global-primary-color);
		font-size: 16px;
		font-weight: 600;
		text-decoration: none;
	  }

	  .redirectIcon {
		width: 9px;
		vertical-align: sub;
	  }

	}

	  .marketingCommsSection {
		  margin: 18px auto 0;
	  }

	.cl-formButtonPrimary {
	  background-color: var(--global-primary-color);
	}

	.cl-footerActionLink {
	  color: var(--global-primary-color);
	  font-size: 14px;
	  font-weight: 600;
	}

	.cl-formFieldInput, .cl-socialButtonsBlockButton {
	  border-radius: 7px;
	  border: 1px solid rgba(0, 0, 0, 0.23);
	  box-shadow: 0 2px 9px -5px rgba(25, 28, 33, 0.20);
	}

	.cl-socialButtonsBlockButton {
	  color: var(--global-primary-color);
	  font-size: 14px;
	  font-weight: 500;
	  justify-content: center;
	  gap: 5px;

	  & > div {
		width: initial;
	  }
  	}

	.cl-socialButtonsBlockButton__google > span > span:nth-child(1) {
	  display: inline-block;
	  background-color: var(--global-primary-color);
	  -webkit-mask-repeat: no-repeat;
	  -webkit-mask-image: url('https://img.clerk.com/static/google.svg');
		height: 100%;

		& > img {
			opacity: 0;
			display: inline-block;
		}
	}

	.cl-formButtonPrimary {
		text-indent: -9999px;
	  }
	  .cl-formButtonPrimary:before {
		text-indent:0;
		float: left;
		display: inline-flex;
		justify-content: center;
		width: 100%;
	  }
	.cl-formButtonPrimary:before {
	  content: 'Start a free trial ►';
	  text-transform: none;
	}

  }
}

@media (min-width: 1441px) {
	.marketingCommsSection {
		width: 55%;
	}

	.cl-signUp-start {
		width: 30rem;
	}
}

@media (max-width: 1440px) and (min-width: 1200px) {
	.marketingCommsSection {
		width: 70%;
	}
}

@media (max-width: 1199px) and (min-width: 768px) {
	.marketingCommsSection {
		width: 90%;
	}
}