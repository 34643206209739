@use 'src/styles/info';
@use 'src/styles/brand';

.noSeatsErrorContainer {
    padding: 30px;
    max-width: 830px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .noSeatsErrorIcon {
        margin-bottom: 16px;
    }
    .noSeatsErrorTitle.MuiTypography-root {
        @include info.title;
        text-align: center;
    }
    .noSeatsErrorSubtitle.MuiTypography-root {
        @include info.subtitle;
        text-align: center;
    }
}