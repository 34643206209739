.plansDialogContainer {
  & .MuiPaper-root {
    box-shadow: none;
    border: solid 1px var(--global-outline-color);
    border-radius: 4px;
    width: 90%;
    max-width: 90%;
    padding-bottom: 25px;
  }

  & .plansDialogTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px var(--global-outline-color);
  }

  & .plansDialogTitle, .plansDialogContent.MuiDialogContent-root {
    padding: 16px 24px;
    color: var(--global-text-primary-color);

    & > p {
      color: var(--global-text-primary-color);
    }
  }

  & .plansDialogCloseIcon {
    cursor: pointer;
  }

  .changePlanConfirmationButtonsContainer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;

    .keepPlanConfirmationButton.MuiButtonBase-root {
      color: var(--global-purple-color);
      border-color: var(--global-purple-color);
    }

    .changePlanConfirmationButton.MuiButtonBase-root {
      background-color: var(--global-purple-color);
      margin-left: 8px;
    }
  }

}