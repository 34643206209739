.planContentContainer {

  .headerWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
  }

  .planContentDescription {

    & > h6 {
      color: #09151D;
      font-size: 16px;
      font-weight: 500;
      line-height: 160%;
    }
  }

  .planContentPeriodContainer {
    margin: 20px 0;
    text-align: center;

    .planContentPeriodSelector {
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.12);

      & > button {
        border: 0;
        color: #193B52;
        font-size: 16px;
        font-weight: 500;
        padding: 5px 75px;

        &.Mui-selected {
          background: rgba(130, 94, 202, 0.08);
        }
      }
    }
  }

  .planContentSeats {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    & > div > p {
      margin-bottom: 5px;
      color: #09151D;
      font-size: 14px;
    }

    & > p {
      margin-left: 5px;
    }
  }

  .plansContentRow {
    display: flex;
    gap: 15px;

    .planContainer {
      position: relative;
      border-radius: 9px;
      border: 2px solid #BBE7ED;
      background: #FFF;
      padding: 35px 55px;
      flex-grow: 1;

      &.selected {
        background: rgba(130, 94, 202, 0.08);
      }

      .currentPlanChip {
        position: absolute;
        top: 0;
        right: 0;
        background-color: #E6DFF4;
        color: #7755CC;
        border-radius: 3px;

        & .MuiChip-label {
          font-size: 1rem;
          font-weight: 500;
        }
      }

      .titleContainer {
        display: flex;
        flex-wrap: wrap;
        margin-left: 8px;

        .planCheckbox {
          padding: 0;
          color: var(--global-primary-color);
        }

        .radioButtonCheckedIcon {
          color: #825ECA;
        }
      }

      .price {
        color: #44546F;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        line-height: 133.4%; /* 24.012px */
      }

      .planTitle {
        margin-bottom: 10px;

        & > .MuiTypography-root {
          color: #44546F;
          font-size: 20px;
          font-weight: 700;
          line-height: 133.4%;
        }

      }

      .planTitle > .MuiTypography-root {
        margin-left: 10px;
        color: #44546F;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        line-height: 133.4%;
      }

      .benefit {
        display: flex;
        gap: 19px;
        margin: 16px 0;

        & > p {
          color: var(--global-text-primary-color);
          font-size: 14px;
          font-weight: 500;
          line-height: 133.4%;
        }

      }
    }
  }

  .planContentContainerRow.summaryContainer {

    .title.MuiTypography-root {
      color: var(--global-text-primary-color);
      font-weight: 700;
      line-height: 133.4%;
      font-size: 20px;
      padding-bottom: 13px;
    }

    .summaryInfo {
      color: #09151D;
    }

    .currentPlanText {
      font-size: 14px;
      font-weight: 600;
    }

    .currentSubscriptionInfo {
      font-size: 14px;
      font-weight: 500;
    }

    .periodCostInfo {
      font-size: 19px;
      font-weight: 600;
    }

    .nextChargeInfo {
      font-size: 12px;
      font-weight: 500;
    }

    .contactSalesText.MuiTypography-root {
      color: var(--global-text-primary-color);
      font-size: 19px;
      font-weight: 600;
      line-height: 133.4%;
    }

    .contactSalesButton {
      margin-top: 20px;
      width: 100%;
    }

    .price.MuiTypography-root {
      color: var(--global-text-primary-color);
      font-size: 50px;
      font-weight: 600;
      line-height: 133.4%;
    }

    .selectPlanButtonContainer {
      height: 100%;
      display: flex;

      .selectPlanButton {
        background-color: var(--global-purple-color);
        flex-grow: 1;
        align-self: flex-end;
        text-align: center;

        &.Mui-disabled {
          background-color: #EAEADF;
        }

        &.ultimate {
          background-color: transparent;
        }
      }
    }
  }
}

@media only screen and (min-width: 950px) and (max-width: 1200px) {
  .planContentContainer {

    .plansContentRow {
      gap: 5px;

      .planContainer.changePlan {
        padding: 30px;
      }
    }
  }

  .planContentContainerRow.summaryContainer > div {
    padding-right: 0;
  }

}

@media only screen and (max-width: 950px) {
  .planContentContainer {

    & > hr {
      visibility: hidden;
    }
  }

}