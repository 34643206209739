@use 'src/styles/palette';

@mixin button {
    &.MuiButton-contained  {
        background-color: palette.$primary-color;
        &:hover {
            background-color: palette.$primary-color;
        }
    }
    &.MuiButton-outlined {
        border-color: palette.$primary-color;
        color: palette.$primary-color;
        &:hover {
            border-color: palette.$primary-color;
            color: palette.$primary-color;
        }
    }
}